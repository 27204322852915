<template>
  <div class="signup-page">
    <transition name="fade" mode="out-in">
      <div class="main-login" v-if="signupStep == 0">
        <div class="welcome-back">
          Apply for a verified club
        </div>
        <label class="form-box-label" :style="firstName.length ? 'opacity: 1; transform: translateY(0px); z-index: 2;' : ''"> First name </label>
        <input type="text" placeholder="First name" class="form-box" v-model="firstName" spellcheck="false"/>
        <label class="form-box-label" :style="lastName.length ? 'opacity: 1; transform: translateY(0px); z-index: 2;' : ''"> Last name </label>
        <input type="text" placeholder="Last name" class="form-box" v-model="lastName" spellcheck="false"/>
        <label class="form-box-label" :style="email.length ? 'opacity: 1; transform: translateY(0px); z-index: 2;' : ''"> Email </label>
        <input type="text" placeholder="Email" class="form-box" v-model="email" spellcheck="false" id="partner-email-input"/>
        <label class="form-box-label" :style="confirmEmail.length ? 'opacity: 1; transform: translateY(0px); z-index: 2;' : ''"> Confirm email </label>
        <input type="text" placeholder="Confirm email" class="form-box" v-model="confirmEmail" spellcheck="false" id="partner-email-input-confirm"/>
        <div class="wrong-login" id="wrong-login"> 
          Please enter a valid email 
        </div>
        <div class="full-width">
          <button class="sign-in" @click="signIn" id="login-button" :style="email.length && firstName.length && lastName.length && confirmEmail.length ? 'opacity: 1' : ''"> 
            <span id="sign-up-text">Continue</span>
            <div class="loader" id="login-loader"/>
          </button>
        </div>
      </div>
      <div class="main-login" v-else-if="signupStep == 1">
        <div class="welcome-back">
          Channel info
        </div>
        <label class="form-box-label" :style="clubName.length ? 'opacity: 1; transform: translateY(0px); z-index: 2;' : ''"> Club name </label>
        <input type="text" placeholder="Club name" class="form-box" v-model="clubName" spellcheck="false"/>
        <label class="form-box-label" :style="membersCount ? 'opacity: 1; transform: translateY(0px); z-index: 2;' : ''"> Members count </label>
        <input type="number" pattern="\d*" placeholder="Member count (estimate)" class="form-box" v-model="membersCount" spellcheck="false"/>
        <label class="form-box-label" :style="clubType.length ? 'opacity: 1; transform: translateY(0px); z-index: 2;' : ''"> Club Type </label>
        <input type="text" placeholder="Club Type (sports, tech, service, etc.)" class="form-box" v-model="clubType" spellcheck="false"/>
        <div class="wrong-login" id="wrong-login"> 
          Please enter a valid email 
        </div>
        <div class="full-width">
          <button class="sign-in" @click="stepTwo" id="login-button" :style="clubName.length && membersCount && clubType ? 'opacity: 1' : ''"> 
            <span id="sign-up-text">Submit</span>
            <div class="loader" id="login-loader"/>
          </button>
        </div>
      </div>  
      <div class="setup-complete" v-else-if="signupStep == 2">
        Congratulations! Your club is being verified. You will receive an email within 24 hours about how to finish the setup process.
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data(){
    return{
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      signupStep: 0,
      clubName: '',
      membersCount: null,
      clubType: '',
    }
  },
  mounted(){
    document.getElementsByClassName('full-footer')[0].style.display = 'none';
    document.getElementById('full-search-bar').style.display = 'none';
    
  },
  unmounted(){
    document.getElementsByClassName('full-footer')[0].style.display = '';
    document.getElementById('full-search-bar').style.display = '';
  },
  methods: {
    signIn: function(){
      document.getElementById('login-loader').style.display = 'inline-block';
      document.getElementById('sign-up-text').innerText = '';
      document.getElementById('wrong-login').style.opacity = 0; 
      setTimeout(() => {
        document.getElementById('login-loader').style.display = 'none';
        document.getElementById('sign-up-text').innerText = 'Continue';
        if(!this.email.includes('@') || !this.email.includes('.') || this.email.length < 5){
          document.getElementById('wrong-login').innerText = 'Please enter a valid email';
          document.getElementById('wrong-login').style.opacity = 1;
          document.getElementById('partner-email-input').style.borderBottom = '2px solid red';
        }
        else if(this.email != this.confirmEmail){
          document.getElementById('wrong-login').innerText = 'Emails do not match';
          document.getElementById('wrong-login').style.opacity = 1;
          document.getElementById('partner-email-input-confirm').style.borderBottom = '2px solid red';
        }
        else
          this.signupStep = 1;   
      } , 1000);
    },
    stepTwo: function(){
      document.getElementById('login-loader').style.display = 'inline-block';
      document.getElementById('sign-up-text').innerText = '';
      const options = {
        method: 'post',
        body: JSON.stringify({first:this.firstName, last: this.lastName, email: this.email, 
                              clubName: this.clubName, membersCount: this.membersCount, clubType: this.clubType, messageType: 'Verify Sign Up'}),
        headers: {'Content-Type': 'application/json'}
      };
      fetch('/api/mail', options);

      setTimeout(() => {
        document.getElementById('login-loader').style.display = 'none';
        this.signupStep = 2;   
      } , 1000);
    }
  }
}
</script>

<style scoped>
  .signup-page{
    padding: 180px 30px;
  }
  .main-login{
    text-align: left;
    width: 100%;
    max-width: 432px;
    margin: 0 auto;
  }
  .welcome-back{
    font-size: 24px;
    margin-bottom: 48px;
    font-weight: 600;
  }
  .own-channel{
    font-size: 16px;
    color: var(--light-gray);
    margin-top: 12px;
  }
  .own-channel-click{
    color: var(--primary-color);
    text-decoration: none;
  }
  .form-box{
    border: none;
    border-bottom: 2px solid var(--lightest-gray);
    margin-bottom: 32px;
    font-size: 16px;
    font-family: inherit;
    padding: 7px 0;
    width: 100%;
    font-weight: 600;
    background: transparent;
  }
  .form-box:focus{
    outline: none;
    border-bottom: 2px solid black;
  }
  .form-box-label{
    opacity: 0;
    transition: .3s all;
    transform: translateY(20px);
    text-align: left;
    font-size: 12px;
    z-index: -2;
    color: var(--light-gray);
    width: 100%;
  }
  .wrong-login{
    width: 60%;
    border: 1px solid red;
    background: #fceae9;
    opacity: 0;
    transition: .3s all;
    padding: 10px 35px;
    border-radius: 4px;
  }
  .full-width{
    width: 100%;
    text-align:end;
    margin-top: 24px;
  }
  .sign-in{
    color: white;
    background: var(--primary-color);
    padding: 10px 35px;
    transition: .5s all;
    border-radius: 20px;
    opacity: 0;
    border: none;
    font-family: inherit;
    font-size: 16px;
    width: 135px;
  }
  .sign-in:hover{
    background: var(--primary-color-light);
  }
  .loader {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid var(--primary-color-light);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 1s ease-in-out infinite;
    display: none;
    margin: 0 auto;
  }
  .setup-complete{
    width: 100%;
    max-width: 500px;
    text-align: center;
    font-size: 20px;
    margin: 0 auto;
    line-height: 30px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>